<template>
  <EditCategoryDialog
    value="true"
    :category="category"
    :error-message="errorMessage"
    :update-loading="updateLoading"
    :data-loading="!!categoryLoading"
    @ok="onOk"
    @cancel="onCancel"
  />
</template>

<script>
import EditCategoryDialog from "./components/EditCategoryDialog";
import {
  CATEGORY_CREATE,
  CATEGORIES_QUERY,
  CATEGORY_QUERY,
  CATEGORY_UPDATE,
} from "./graphql";

export default {
  name: "EditCategoryDialogView",
  components: { EditCategoryDialog },
  props: {
    storeId: {
      type: [Number, String],
      required: true,
    },
    categoryId: {
      type: [Number, String],
      default: undefined,
    },
  },
  data() {
    return {
      category: {},
      errorMessage: undefined,
      updateLoading: false,
      categoryLoading: 0,
    };
  },
  apollo: {
    category: {
      loadingKey: "categoryLoading",
      query: CATEGORY_QUERY,
      variables() {
        return {
          storeId: this.storeId,
          id: this.categoryId,
        };
      },
      skip() {
        return !this.categoryId;
      },
      update(data) {
        return data.category;
      },
    },
  },
  methods: {
    onCancel() {
      this.$router.back();
    },
    async onOk(createOrUpdateCategory) {
      this.errorMessage = undefined;
      this.updateLoading = true;
      let result = undefined;

      if (!this.categoryId) {
        try {
          result = await this.handleCreateCategory(createOrUpdateCategory);
        } catch (error) {
          this.errorMessage = error.toString();
          this.updateLoading = false;
          return;
        }

        // TODO - what with this ?
        // why for creation we need to add something and for update we do not ?
        this.mutationUpdate(this.$apollo.getClient().cache, result);

        this.updateLoading = false;
        this.$router.back();
      } else {
        try {
          result = await this.handleUpdateCategory(
            this.categoryId,
            createOrUpdateCategory
          );
        } catch (error) {
          this.errorMessage = error.toString();
          this.updateLoading = false;
          return;
        }

        this.updateLoading = false;
        this.$router.back();
      }
    },
    mutationUpdate(cache, result) {
      const newCategory = result?.data?.createCategory?.category;

      const variables = { storeId: this.storeId };
      const { categories } =
        cache.readQuery({
          query: CATEGORIES_QUERY,
          variables,
        }) || [];

      const index = categories.findIndex((x) => x.id === newCategory.id);

      if (index !== -1) return;

      categories.push(newCategory);
      cache.writeQuery({
        query: CATEGORIES_QUERY,
        variables,
        data: { categories },
      });
    },
    async handleCreateCategory(category) {
      const result = await this.$apollo.mutate({
        mutation: CATEGORY_CREATE,
        variables: {
          name: category.name.toString(), // To ensure if someone writes just number to have a string
        },
      });

      const errors = result?.data?.createCategory?.errors || [];

      if (errors.length) {
        throw Error(errors.join("; "));
      }

      return result;
    },
    async handleUpdateCategory(categoryId, category) {
      const result = await this.$apollo.mutate({
        mutation: CATEGORY_UPDATE,
        variables: {
          id: categoryId,
          name: category.name.toString(),
        },
      });

      const errors = result?.data?.updateCategory?.errors || [];

      if (errors.length) {
        throw Error(errors.join("; "));
      }

      return result;
    },
  },
};
</script>
