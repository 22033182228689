<template>
  <VxDialog
    v-model="modelValue"
    :h-size="'xsmall'"
    v-bind="$props"
    persistent
    :title="dialogTitle"
    @back="cancel"
  >
    <VxForm v-if="!dataLoading" ref="form" @submit="ok">
      <slot name="description"></slot>
      <v-container>
        <VxTextField
          v-model.number="form.name"
          name="categoryName"
          :label="
            $t('backstock.setup.categories.editCategoryDialog.categoryLabel')
          "
          :placeholder="
            $t(
              'backstock.setup.categories.editCategoryDialog.categoryPlaceholder'
            )
          "
          rules="required"
        />
      </v-container>
      <VxAlert v-if="errorMessage" type="error">
        {{ errorMessage }}
      </VxAlert>
    </VxForm>
    <template #actions>
      <VxBtn secondary @click="cancel">
        {{
          $t("backstock.setup.categories.editCategoryDialog.cancelButtonText")
        }}
      </VxBtn>
      <VxBtn text :loading="updateLoading" @click="submit">
        {{ $t("backstock.setup.categories.editCategoryDialog.okButtonText") }}
      </VxBtn>
    </template>
  </VxDialog>
</template>

<script>
import { useFormObject } from "@/mixins/useFormObject";
import {
  VxTextField,
  VxAlert,
  VxDialog,
  VxBtn,
  VxForm,
  useModelValue,
  useResponsiveness,
} from "@/core-ui";

export default {
  name: "EditCategoryDialog",
  components: { VxTextField, VxAlert, VxDialog, VxBtn, VxForm },
  mixins: [
    useModelValue(),
    useResponsiveness(),
    useFormObject({
      prop: "category",
      default: { id: 0, name: "" },
    }),
  ],
  props: {
    errorMessage: {
      type: String,
      default: "",
    },
    updateLoading: {
      type: Boolean,
      default: false,
    },
    dataLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isEditMode() {
      return this.category.id > 0;
    },
    dialogTitle() {
      return this.dataLoading
        ? this.$t("backstock.setup.categories.editCategoryDialog.loadingText")
        : this.isEditMode
        ? this.$t("backstock.setup.categories.editCategoryDialog.titleEdit")
        : this.$t("backstock.setup.categories.editCategoryDialog.titleAdd");
    },
  },
  methods: {
    getValidator() {
      return this.$refs.form;
    },
    cancel() {
      this.modelValue = false;
      this.$emit("cancel");
    },
    submit() {
      this.$refs.form.submit();
    },
    ok() {
      this.modelValue = false;
      this.$emit("ok", { ...this.form });
    },
  },
};
</script>
